import moment from 'moment-timezone';

import {TIME_API_FORMAT} from '@/constants/timeFormats';
import {AppointmentRequestDetailModel, SendAppointmentRequestDTO} from '@/types/gatewayDataModels';
import {formatDateTime, getDateTimeFormats} from '@/utils/timeFormatter';

const {TIME_FORMAT, DATE_FORMAT} = getDateTimeFormats();

const formatTimeField = (timeField: string) =>
    timeField ? formatDateTime(timeField, TIME_API_FORMAT, TIME_FORMAT) : '';

const formatDataToApi = (values: SendAppointmentRequestDTO) => {
    const arrivalTime = formatTimeField(values.arrivalTime);

    return {...values, arrivalTime};
};

const getPatientNowTime = (patientTimeZone: string) => moment().tz(patientTimeZone).format(TIME_FORMAT);

const getDefaultClinicalInfo = (appointmentRequestDetails: AppointmentRequestDetailModel) => {
    const {primaryDiagnosis, diagnoses, precautions, orders} = appointmentRequestDetails;
    let text = orders.reduce((acc, {activities, orderName, clinicalInfo, comment}, index) => {
        const activitiesNames = activities.map(({name}) => name);
        const clinicalInfoText = clinicalInfo ? `\nClinical Info: ${clinicalInfo}` : '';
        const commentsText = comment ? `\nComments: ${comment}` : '';
        return (
            acc +
            `Order ${index + 1} - ${orderName}\n${activitiesNames.join('\n')}${clinicalInfoText}${commentsText}\n\n`
        );
    }, '');

    if (precautions?.length) {
        text += `Precautions:\n${precautions
            .map(
                ({name, startDate, endDate}) =>
                    `${formatDateTime(startDate, DATE_FORMAT)} - ${formatDateTime(endDate, DATE_FORMAT)} ${name}`,
            )
            .join('\n')}\n`;
    }

    if (primaryDiagnosis?.text) {
        text += `Cesia Diagnosis: ${primaryDiagnosis?.text}\n`;
    }

    if (diagnoses?.length) {
        text += `HL7 Diagnoses: ${diagnoses.map(({text}) => text).join('; ')}\n`;
    }
    return text;
};

export {formatDataToApi, getDefaultClinicalInfo, getPatientNowTime};
