import {ChangeEvent, FocusEvent, ReactNode, Ref, useEffect, useState} from 'react';
import {usePreviousDistinct} from 'react-use';
import moment from 'moment-timezone';

import {getDateTimeFormats} from '@/utils/timeFormatter';

import MHTextField from './MHTextField';

const {TIME_FORMAT} = getDateTimeFormats();

type PropsType = {
    name?: string;
    placeholder?: string;
    errorMessage?: string;
    label?: string | ReactNode;
    showRequiredAsterisk?: boolean;
    disabled?: boolean;
    rootClassName?: string;
    icon?: ReactNode;
    className?: string;
    externalErrorClassName?: string;
    onBlur?: () => void;
    onChange?: (time: string) => void;
    onChangeHandler?: (time: string) => void;
    startTime?: string;
    outputFormat?: string;
    value?: string;
    inputRef?: Ref<any>;
    patientTimeZone?: string;
};

const MHTimePicker = ({
    name,
    placeholder = TIME_FORMAT,
    errorMessage,
    label,
    showRequiredAsterisk,
    disabled,
    rootClassName,
    icon,
    className,
    externalErrorClassName,
    onBlur,
    onChange,
    startTime,
    // Please use OnChange and NOT onChangeHandler
    onChangeHandler,
    outputFormat = TIME_FORMAT,
    value,
    inputRef,
    patientTimeZone,
}: PropsType) => {
    const [time, setTime] = useState('');
    const [prevTimeValue, setPrevTimeValue] = useState('');
    const prevValue = usePreviousDistinct(value);

    useEffect(() => {
        if (value !== prevValue) {
            setTime(value);
            setPrevTimeValue(value);
        }
    }, [prevValue, value]);

    const shortcutsMap: {[key: string]: () => moment.Moment} = {
        N: () => (patientTimeZone ? moment().tz(patientTimeZone) : moment()),
        'N+1': () => (patientTimeZone ? moment().add(1, 'minutes').tz(patientTimeZone) : moment().add(1, 'minutes')),
    };

    const parseShortcut = (string: string) => (shortcutsMap[string] ? shortcutsMap[string]() : string);

    const onBlurLocalHandler = ({target}: FocusEvent<HTMLInputElement>) => {
        const changeHandler = onChangeHandler || onChange;

        let value = target.value.slice();
        if (startTime && (startTime.includes('PM') || startTime.includes('pm'))) {
            value = `${target.value} pm`;
        }
        const parsedValue = parseShortcut(value.toUpperCase());
        const timeObj = moment(
            parsedValue,
            [
                'H',
                'HH',
                'Hmm',
                'H,mm',
                'H mm',
                'H-mm',
                'H:mm',
                'H A',
                'HH A',
                'Hmm A',
                'H,mm A',
                'H mm A',
                'H-mm A',
                'H:mm A',
            ],
            true
        );

        let newTimeValue = prevTimeValue;

        if (timeObj.isValid()) {
            newTimeValue = timeObj.format(outputFormat);
        }

        if (!target.value) {
            newTimeValue = '';
        }

        setTime(newTimeValue);
        setPrevTimeValue(newTimeValue);
        changeHandler(newTimeValue);
        onBlur?.();
    };

    const onChangeLocalHandler = ({target}: ChangeEvent<HTMLInputElement>) => {
        setTime(target.value);
    };

    return (
        <MHTextField
            name={name}
            inputRef={inputRef}
            showRequiredAsterisk={showRequiredAsterisk}
            label={label}
            disabled={disabled}
            placeholder={placeholder}
            value={time}
            fullWidth
            rootClassName={rootClassName}
            onChange={onChangeLocalHandler}
            onBlur={onBlurLocalHandler}
            onClick={(e) => {
                const target = e.target as HTMLInputElement;

                target.select && target.select();
            }}
            errorMessage={errorMessage}
            icon={icon}
            className={className}
            externalErrorClassName={externalErrorClassName}
        />
    );
};

export default MHTimePicker;
