import moment from 'moment-timezone';

import {ISO_LOCAL_DATE_TIME} from '@/constants/timeFormats';
import {formatDateTimeFromGivenToUTC, formatDateTimeToPatient, getDateTimeFormats} from '@/utils/timeFormatter';

const {NEW_DATE_TIME_FORMAT} = getDateTimeFormats();

const convertDateTimeToUTC = (dateTime: string) => {
    return formatDateTimeFromGivenToUTC(dateTime, ISO_LOCAL_DATE_TIME, NEW_DATE_TIME_FORMAT, moment.tz.guess());
};
const convertDateTimeToUserTimezone = (dateTime: string) => {
    return formatDateTimeToPatient(dateTime, NEW_DATE_TIME_FORMAT, ISO_LOCAL_DATE_TIME, moment.tz.guess(), true);
};

export {convertDateTimeToUserTimezone, convertDateTimeToUTC};
